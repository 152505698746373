import kebabCase from 'lodash.kebabcase';
import type {
  BrandablePageType,
  BrandedPage,
  Category,
  CreditCard,
  User,
} from '@cardo/types';
import {
  AwardRibbon,
  BarChart,
  Coins,
  Hotel,
  MoneyBill,
  Plane,
  Suitcase,
} from '@cardo/ui';

export const topPicksCategories: Category[] = [
  {
    label: 'Flexible Points',
    value: 'flexible-points',
    icon: <Coins />,
    subcategories: [
      { label: 'Bilt', value: 'bilt' },
      { label: 'Chase', value: 'chase' },
      { label: 'American Express', value: 'american-express' },
      { label: 'Citi', value: 'citi' },
      { label: 'Capital One', value: 'capital-one' },
    ],
  },
  {
    label: 'Cash Back',
    value: 'cash-back',
    icon: <MoneyBill />,
    subcategories: [
      { label: 'No Annual Fee', value: 'no-annual-fee' },
      { label: 'No FX Fees', value: 'no-foreign-transaction-fees' },
      { label: 'Student', value: 'student' },
      { label: 'Business', value: 'business' },
    ],
  },
  {
    label: 'Hotel',
    value: 'hotel',
    icon: <Hotel />,
    subcategories: [
      { label: 'Hilton', value: 'hilton' },
      { label: 'Hyatt', value: 'hyatt' },
      { label: 'IHG', value: 'ihg' },
      { label: 'Marriott', value: 'marriott' },
    ],
  },
  {
    label: 'Airline',
    value: 'airline',
    icon: <Plane />,
    subcategories: [
      { label: 'American Airlines', value: 'american-airlines' },
      { label: 'Delta', value: 'delta' },
      { label: 'United', value: 'united' },
      { label: 'Southwest', value: 'southwest' },
    ],
  },
  {
    label: 'Business',
    value: 'business',
    icon: <Suitcase />,
    subcategories: [
      { label: 'Flexible Points', value: 'flexible-points' },
      { label: 'Cash Back', value: 'cash-back' },
      { label: 'Hotel', value: 'hotel' },
      { label: 'Airline', value: 'airline' },
    ],
  },
];

const categoryIcons: { [key: string]: JSX.Element } = {
  'cash-back': <MoneyBill />,
  airline: <Plane />,
  business: <Suitcase />,
  'top-cards': <AwardRibbon />,
  travel: <Plane />,
  'building-credit': <BarChart />,
};

export function getCategoryIcon(categoryId: string) {
  return categoryIcons[categoryId];
}

export const brandablePageTypes: BrandablePageType[] = [
  {
    slug: 'top-picks',
    name: 'Top Picks',
    description:
      'Use this page type to list all of your top credit cards. If you also have Top Picks Category pages, this will be your base page. All category pages will be nested under this page.',
    createable: false,
  },
  {
    slug: 'top-picks-category',
    name: 'Top Picks Category',
    description:
      'Use this page type to have multiple categories of Top Picks. If you do not also have a Top Picks page, the first category page you create will be your base page.',
    createable: false,
  },
  {
    slug: 'single-card',
    name: 'Single Card',
    description:
      "Use this product page to highlight a single card. You can add your take on the card's features. The card's calculator will be included on this page if it exists.",
    createable: true,
  },
];

export function generateSingleCardPageTypeSlug({
  username,
  cardName,
}: {
  username: string;
  cardName: string;
}) {
  // removes all special characters
  const sanitizedCardName = cardName.replace(/[^\w\s]/gi, '');
  const sanitizedUsername = username.replace(/[^\w\s]/gi, '');
  return `${sanitizedUsername.replace(/\s/g, '').toLowerCase()}__${kebabCase(
    sanitizedCardName
  )}`;
}

export function sanitizeUsername(username: string) {
  return username
    .replace(/[^\w\s]/gi, '')
    .replace(/\s/g, '')
    .toLowerCase();
}

export function getBrandedPageTypePath(brandedPage: BrandedPage) {
  if (isSingleCardPageType(brandedPage)) {
    return getSingleCardPageTypePath(brandedPage.attributes.slug);
  }
  return getTopPicksPageTypePath(brandedPage.attributes.slug);
}

export function getSingleCardPageTypePath(slug: string) {
  const parts = slug.split('__');

  if (parts.length === 1) {
    return `/i/${parts[0]}`;
  }

  return `/i/${parts[0]}/${parts[1]}`;
}

export function getTopPicksPageTypePath(slug: string) {
  return `/i/${slug}`;
}

export function getInfluencerCalculatorLink({
  creditCard,
  influencer: { username },
}: {
  creditCard: CreditCard;
  influencer: User['user'];
}) {
  if (!creditCard.attributes.calculator.data) {
    return null;
  }
  const cardName = creditCard.attributes.cardName.toLowerCase();
  const slug = generateSingleCardPageTypeSlug({ username, cardName });
  return getSingleCardPageTypePath(slug);
}

export function getSingleCardPageTypeCardName(brandedPage: BrandedPage | null) {
  if (!brandedPage) {
    return null;
  }
  const firstBlock = brandedPage.attributes.blocks[0];
  // @ts-expect-error - need better types here but this works for now
  return firstBlock?.creditCard?.data.attributes?.cardName;
}

export function isSingleCardPageType(brandedPage: BrandedPage) {
  return brandedPage.attributes.pageType === 'single-card';
}

export function getSubcategoryKeyFromCombinedKey(subcategoryKey: string) {
  const parts = subcategoryKey.split('__');
  return parts[parts.length - 1];
}
